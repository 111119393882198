<template>
  <!-- 开发者 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <le-card title="开发者">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="delMenu" v-if="isShowBtn('ab463b464a264d548e541dd7')">删除</lbButton>
          <lbButton type="warning" icon="bianji" @click="handelDeveloperManage(editId)" v-if="editId && isShowBtn('7fb05caa611a4520b92db4df')">编辑
          </lbButton>
          <lbButton icon="xinzeng" @click="handelDeveloperManage('')"
            v-if="isShowBtn('6ab952a9e3a64465817fc612')">新增</lbButton>
        </div>
      </template>
      <div>
        <div class="content_box" v-if="treeData.length > 0">
          <el-tree node-key="id" :data="treeData" :props="defaultProps" default-expand-all class="tree_box"
            :default-expanded-keys="defaultExpandKeys" @node-click="nodeClick">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span :style="data.id == id ? 'color:#409EFF;' : ''"> {{ node.label }}</span>
            </span>
          </el-tree>
          <div class="vertical_box">
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="right_content_box" id="content" v-html="content"></div>
        </div>
        <div class="empty_box" v-else>
          暂无数据
        </div>
      </div>
    </le-card>
    <!-- 新增编辑 -->
    <lebo-dialog append-to-body :title="dialogDeveloperManageId?'编辑':'新增'" :isShow="showDeveloperManage" width="65%"
      @close="showDeveloperManage = false" footerSlot>
      <addDeveloperManage @closeDialog="closeDeveloperManage" v-if="showDeveloperManage" :id="dialogDeveloperManageId"></addDeveloperManage>
    </lebo-dialog>
  </div>
</template>

<script>
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import E from 'wangeditor'
import { getMaintenanceMain, delMaintenanceMain, getSelectList } from '@/api/maintenanceCenter'
import addDeveloperManage from './components/addDeveloperManage/index.vue'
export default {
  components: { addDeveloperManage },
  data () {
    return {
      // 左侧树控件
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      defaultExpandKeys: [],
      // 当前展示的节点
      id: '',
      editId: '',
      content: null,
      showDeveloperManage: false,
      dialogDeveloperManageId: ''
    }
  },
  created () {
  },
  mounted () {
    this.fnGetMaintenanceMain()
  },
  methods: {
    // 获取开发者数据
    async fnGetMaintenanceMain () {
      const res = await getMaintenanceMain({
        type: 1
      })
      console.log('获取开发者数据---', res)
      if (res.Code === 200 && res.Data && res.Data.length > 0) {
        this.treeData = res.Data
        if (!this.id) {
          this.filterTreeData()
        }
      } else {
        this.treeData = []
        this.content = ''
      }
    },
    filterTreeData () {
      var statusList = []
      for (var i = 0; i < this.treeData.length; i++) {
        statusList.push({
          status: false,
          id: ''
        })
        this.setStatusData(statusList[i], this.treeData[i])
      }
      var index = statusList.findIndex(item => item.status)
      this.id = statusList[index].id
      this.editId = statusList[index].id
      this.content = this.findTreeData(this.treeData, statusList[index].id)
    },
    setStatusData (status, item) {
      if (item.content) {
        if (!status.id) {
          status.status = true
          status.id = item.id
        }
      } else {
        if (item.children && item.children.length > 0) {
          item.children.forEach(data => {
            this.setStatusData(status, data)
          })
        }
      }
    },
    findTreeData (data, id) {
      var str
      data.forEach(item => {
        if (item.id === id) {
          return str = item.content
        } else {
          if (item.children && item.children.length) {
            if (!str) {
              return str = this.findTreeData(item.children, id)
            }
          }
        }
      })
      return str
    },
    // 点击树节点
    nodeClick (data, node, key) {
      // console.log('nodeClick--------', data);
      // console.log('nodeClick--------', node);
      // console.log('nodeClick--------', key);
      this.id = data.id
      this.defaultExpandKeys = [data.id]
      if (data.content) {
        this.editId = data.id
        this.content = data.content
      } else {
        this.editId = null
      }
    },
    // 删除菜单
    delMenu () {
      if (this.id) {
        this.$confirm('此操作将永久删除该菜单类别, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await delMaintenanceMain({
            id: this.id
          })
          if (res && res.Code === 200) {
            this.$msg.success(res.Message)
          }
          this.fnGetMaintenanceMain()
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的菜单！')
      }
    },
    // 新增编辑
    handelDeveloperManage (id) {
      this.dialogDeveloperManageId = id
      this.showDeveloperManage = true
    },
    closeDeveloperManage () {
      this.showDeveloperManage = false
      this.fnGetMaintenanceMain()
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  height: 97%;
}

.content_box {
  display: flex;
  height: 680px;

  .el-tree {
    width: 20%;
    overflow: auto;
    max-height: 680px;
  }

  /deep/ .vertical_box {
    height: inherit;
    padding: 0 10px;

    .el-divider--vertical {
      height: 100%;
    }
  }

  .right_content_box {
    flex: 1;
    overflow: auto;
    max-height: 680px;
  }
}

.empty_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 680px;
  height: 100%;
}</style>
